@import '@assets/styles/extends.scss';
@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.vehicle-private-hire {
  .form-private-hire {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    fieldset {
      display: flex;
      flex-wrap: wrap;
      gap: 15px 30px;
      width: 100%;

      .field {
        font-family: $font-default;
        flex: 50%;
        max-width: calc(50% - 15px);
        padding-bottom: 12px;
      }
    }

    footer {
      .btn-submit {
        margin-left: auto;
      }
    }

    > section {
      &:not(:first-child) {
        padding: 0 0 1rem;
      }

      &.user-info {
        color: $color-white;
        padding: 1rem 0 0;
      }

      > h2 {
        align-items: center;
        color: $color-white;
        display: flex;
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 1rem;
        position: relative;
        text-transform: capitalize;
        white-space: nowrap;
        width: 100%;

        &::after {
          background-color: $color-white;
          content: '';
          height: 1px;
          margin-left: 15px;
          overflow: hidden;
          width: -webkit-fill-available;
        }

        .icon {
          align-items: center;
          background-color: $color-blue-dark-2;
          border-radius: 50px;
          display: flex;
          height: 36px;
          justify-content: center;
          margin-right: 0.75rem;
          min-width: 36px;
          padding: 0.5rem;
          width: 36px;

          svg {
            display: inline-block;
            fill: $theme-dark-color-blue-light-1;
          }
        }
      }

      > h3 {
        color: $color-white;
        font-size: 16px;
        margin: 1.4rem 0 0.85rem;
      }

      .field {
        &.booking-form-type {
          margin: 0 0 1.2rem;
          padding: 0.25rem 0 1rem;

          label {
            font-size: 16px;
            font-weight: 500;
          }
        }

        &.transfer-type {
          width: 100%;

          .transfer-type-label {
            align-items: center;
            display: flex;
            gap: 10px;
            justify-content: center;
          }

          &:has([data-disabled]) {
            background: #82c91e;
          }

          svg {
            fill: $color-black;
          }

          [data-active],
          [data-disabled] {
            color: $color-white;

            svg {
              fill: $color-white;
            }
          }
        }

        .switch {
          padding: 1.75rem 0 0;
        }
      }
    }

    &.theme-dark {
      .date-picker-label,
      .dropdown-label,
      .input-text-label,
      .multi-select-label,
      .switch-label,
      .textarea-label {
        font-size: 16px;
      }
    }
  }

  .form-private-vehicle-success {
    color: $color-white;
    display: flex;
    flex-direction: column;
    margin: 5rem auto 1rem;
    max-width: 520px;

    .status {
      background-color: $color-green-light-2;
      border-radius: $border-radius-small;
      margin: 0 0 2rem;
      padding: 1.3rem;

      h2 {
        font-size: 1.4rem;
        font-weight: 500;
        margin: 0 0 1rem;
      }
    }

    .title {
      align-items: center;
      display: flex;
      font-size: 1.4rem;
      font-weight: 400;
      gap: 15px;
      justify-content: center;
      margin: 0 0 2rem;

      svg {
        fill: $color-white;
      }
    }

    footer {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      width: 100%;

      .btn {
        flex: 100%;

        &:hover {
          color: $color-white;
        }
      }
    }
  }
}

@import '@assets/styles/variables.scss';

.date-picker-wrapper {
  svg {
    fill: $theme-dark-color-grey-5;
  }
}

.date-picker-input {
  font-family: $font-default;
}

.date-picker-day {
  &[data-weekend] {
    color: $color-black;
  }

  &[data-selected] {
    color: $color-white;
  }

  &[data-today]:not([data-selected='true']) {
    color: $theme-dark-color-accent;
    font-weight: bold;
  }
}
